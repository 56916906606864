import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: null,
        user: null,
        check: false,
        ready: false
      },
      getters: {
        authenticated(state) {
          // return state.token && state.user
          return state.token
        },
        user(state) {
          return state.user
        },
        check(state){
          return state.check
        },
        ready(state){
          return state.ready
        }
      },
      mutations: {
        SET_TOKEN(state, token) {
          state.token = token;
        },
        SET_USER(state, data) {
          state.user = data;
        },
        SET_CHECK(state, data) {
          state.check = data;
        },
        SET_READY(state, data) {
          state.ready = data;
        }
      },
      actions: {
        async login({ dispatch }, credentials) {
          let response = await axios.post("/login", credentials);
          return dispatch('attempt', response.data.token);
        },
        async attempt({ commit, state }, token) {
          if(token){
            commit('SET_TOKEN', token);
          }
    
          if(!state.token){
            commit('SET_CHECK', true);
            return
          }
    
          try {
            let response = await axios.post('/auth/me');
            // console.log(response.data)
            commit('SET_USER', response.data);
          } catch (e) {
            commit('SET_TOKEN', null);
            commit('SET_USER', null);
          }
          commit('SET_CHECK', true);
        },
        logout({commit}){
          return axios.post('auth/logout').then( () => {
            commit('SET_TOKEN', null);
            commit('SET_USER', null);
          })
        }
      }
})