import store from '../store'
import router from './index'
import $ from 'jquery';



//SI ACTUALIZA LA PAGINA CHECKEA SI TIENE EL TOKEN EN LOCALSTORAGE

router.beforeEach((to, from, next) => {

  var containerElement = '.app-container';
  $(containerElement).removeClass('sidebar-mobile-open');
  
  if (store.state.user) {
    routerMiddleware(to, next)
  } else {
    store.commit('SET_READY', false);
    store.commit('SET_CHECK', false);
    store.dispatch('attempt', localStorage.getItem('token'))
      .then(() => {
        store.commit('SET_READY', true);
        routerMiddleware(to, next)
      });
  }

})

function routerMiddleware(to, next) {
  if (to.name !== 'Login' && !store.state.token) {
    if (to.name == 'Register') {
      next()
    } else if (to.name == 'MailRecuperarPassword') {
      next()
    } else if (to.name == 'RecuperarPassword') {
      next()
    } else if (to.name == 'ValidarRecibo') {
      next()
    } else {
      next({ name: 'Login' })
    }
  } else if (to.name === 'Login' && store.state.token) {
    next('/');
  }
  else if (to.name == 'Inicio' && store.state.user) {
    if (store.state.user.tipo == 'user' || store.state.user.tipo == 'admin') {
      next({ name: 'MisRecibos' })
    }
    else {
      next()
    }
  }
  else {
    next()
  }
}