<template>
<div class="block" style="z-index:999;">
  <div class="loader">
    <div class="ball-pulse-sync">
      <div class="bg-success"></div>
      <div class="bg-success"></div>
      <div class="bg-success"></div>
    </div>
    <h4>{{msg ? msg :'Cargando'}}...</h4>
  </div>
</div>
</template>
<script>
export default {
  name: "BlockInterface",
  data() {
    return {
      puntos: "...",
      msg: "",
    };
  },
  props: ["mensaje"],
  mounted() {
    let modal = document.querySelector(".modal-mask");
    modal.style.display = "block";
    modal.append(this.$el);
    document.querySelector("body").style.overflow = "hidden";
    let modalCount = localStorage.getItem('modal')
    localStorage.setItem('modal', parseInt(modalCount)+1)
    this.msg = this.mensaje;
  },
  destroyed() {
    
    let modalCount = localStorage.getItem('modal')
    localStorage.setItem('modal', modalCount-1)
    if(modalCount == 1){
      let modal =  document.querySelector('.modal-mask');
      modal.style.display = "none";
      document.querySelector('body').style.overflow = 'auto';
    }
  },
};
</script>
<style>
.block{
  width:100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader{
  background:white;
  padding:30px;
  width:auto;
  height:auto;
  border-radius: 6px;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
}
.loader h4 {
  margin-top: 25px;
  margin-bottom: 0;
  font-size: 1.2rem;
}
</style>