<template>
  <div class="h-100 d-flex justify-content-center align-items-center flex-column" style="background-color:#efefef;">
    <div class="loader" style="">
      <div class="line-scale">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="app-logo" :class="logoClass"></div>
  </div>
</template>
<script>
  export default {
    name: "LoaderApp",
    computed: {
      logoClass: () => {
        if (window.location.hostname == "recibos.pampadev.com.ar") {
          return " desarrollo ";
        } else if (window.location.hostname == "hma.misrecibosdigitales.com.ar") {
          return " ima ";
        } else if (
          window.location.hostname == "isnsl.misrecibosdigitales.com.ar"
        ) {
          return " isnsl ";
        } else if (
          window.location.hostname == "nsl.misrecibosdigitales.com.ar"
        ) {
          return " isnsl ";
        } else if (
          window.location.hostname == "diocesis.misrecibosdigitales.com.ar"
        ) {
          return " diocesis ";
        } else if (
          window.location.hostname == "isjb.misrecibosdigitales.com.ar"
        ) {
          return " isjb ";
        } else {
          return " desarrollo ";
        }
      },
    }
  };
</script>
<style scoped>

  .loader{
    box-shadow:none;background-color:#efefef;
  }
  .line-scale>div{
    background-color: #444;
  }
  .app-logo{
    position:relative;
  }
  .app-logo::before{
    content:"";
    position:absolute;
    top:0;
    left:0;
    width: 1px;
    background-color: #efefef;
    box-shadow: 0px 0px 10px 10px #efefef;
    height:100%;
    -webkit-animation-name: moving;
    animation-name: moving;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
  @keyframes moving {
     from {left:0}
     to   {left:100%}
  }
</style>
