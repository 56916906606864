import Axios from 'axios'

if(window.location.hostname == 'recibos.pampadev.com.ar'){
    Axios.defaults.baseURL = "https://recibosapi.pampadev.com.ar/api";
  }else if(window.location.hostname == 'hma.misrecibosdigitales.com.ar'){
    Axios.defaults.baseURL = "https://salesianasapi.misrecibosdigitales.com.ar/api";
  }else if(window.location.hostname == 'isnsl.misrecibosdigitales.com.ar'){
    Axios.defaults.baseURL = "https://isnslapi.misrecibosdigitales.com.ar/api";
  }else if(window.location.hostname == 'nsl.misrecibosdigitales.com.ar'){
    Axios.defaults.baseURL = "https://nslapi.misrecibosdigitales.com.ar/api";
  }else if(window.location.hostname == 'diocesis.misrecibosdigitales.com.ar'){
    Axios.defaults.baseURL = "https://diocesisapi.misrecibosdigitales.com.ar/api";
  }else if(window.location.hostname == 'isjb.misrecibosdigitales.com.ar'){
    Axios.defaults.baseURL = "https://isjbapi.misrecibosdigitales.com.ar/api";
  }else{
    Axios.defaults.baseURL = "http://localhost:8000/api";
  }

const token = localStorage.getItem('token')
if (token) {
    Axios.defaults.headers.common['Authorization'] = token
}
