<template>
  <div>
    <template>
      <loader-app v-if="!ready"></loader-app>
      <router-view v-else></router-view>
    </template>
    <BlockInterface v-if="loading"></BlockInterface>
    <div class="modal-mask"></div>
  </div>
</template>

<script>
import BlockInterface from "@/components/layouts/BlockInterface";
import LoaderApp from "@/components/layouts/LoaderApp";
import Axios from "axios";
import Vuex from "vuex";
export default {
  name: "App",
  components: {
    BlockInterface,
    LoaderApp,
  },
  data: () => ({
    mounted: false,
    requestsCount: 0,
  }),
  computed: {
    loading: function () {
      if (this.requestsCount > 0) {
        return true;
      }
      return false;
    },
    ...Vuex.mapState(["ready"]),
  },
  created() {
    Axios.interceptors.request.use(
      (config) => {
        this.requestsCount++;
        // trigger 'loading=true' event here
        return config;
      },
      (error) => {
        // trigger 'loading=false' event here

        this.requestsCount--;
        return Promise.reject(error);
      }
    );
    Axios.interceptors.response.use(
      (response) => {
        // trigger 'loading=false' event here

        this.requestsCount--;

        return response;
      },
      (err) => {
        // trigger 'loading=false' event here
        this.requestsCount--;
        return new Promise(function (resolve, reject) {
          throw err;
        });
      }
    );
  },
  mounted() {
    this.mounted = false;
  },
};
</script>