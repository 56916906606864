import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import Vuelidate from 'vuelidate'
import './assets/css/base.scss';
import store from './store'
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';


import './assets/css/bootstrap-float-label.css';
import './assets/css/logos.css';
import './assets/css/modal.css';

require('@/store/subscribe.js');
require('./axios.js');
require('./router/middleware.js');
// require('./assets/js/styles.js');
localStorage.setItem('modal', 0)


Vue.config.productionTip = false

Vue.use(Vuelidate);




const moment = require('moment')
require('moment/locale/es')
Vue.use(require('vue-moment'), {
  moment
})


Vue.filter('cuil', function (value) {
  if (!value) return ''
  value = value.toString()
  if (value.length == 11) {
    let values = value.split("");
    values.splice(2, 0, "-");
    values.splice(11, 0, "-");
    return values.join("");
  }
  return value;

})
Vue.filter('numFormat', numFormat(numeral));

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
});

Vue.prototype.$iconoTitulo = ""
Vue.prototype.$titleCard = ""
Vue.prototype.$toCreateAction = ""
Vue.prototype.$toCreateText = ""

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
