import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const liquidacion = [
    {
        path: "/liquidaciones/carga-masiva",
        name: "CargaMasiva",
        component: () => import('@/views/liquidaciones/CargaMasiva'),
        props: { titleCard: "Cargar Liquidación", iconoTitulo: 'fas fa-file'},
    },
    {
        path: "/liquidaciones/listar",
        name: "ListarLiquidaciones",
        component: () => import('@/views/liquidaciones/ListarLiquidaciones'),
        props: { titleCard: "Listar liquidaciones", iconoTitulo: 'fas fa-file-alt'},
    },
    {
        path: "/liquidaciones/ver/:id",
        name: "VerLiquidacion",
        component: () => import('@/views/liquidaciones/VerLiquidacion'),
        props: { titleCard: "Ver Liquidacion", iconoTitulo: 'fas fa-file-alt' },
    },
]
const users = [
    {
        path: "/usuarios/listar",
        name: "ListarUsuarios",
        component: () => import('@/views/usuarios/ListarUsuarios'),
        props: { titleCard: "Usuarios", iconoTitulo: 'fas fa-user', toCreateAction: '/usuarios/nuevo', toCreateText: 'Nuevo usuario' },
    },
    {
        path: "/usuarios/nuevo",
        name: "NuevoUsuario",
        component: () => import('@/views/usuarios/NuevoUsuario'),
        props: { titleCard: "Nuevo Usuario", iconoTitulo: 'fas fa-user' },
    },
    {
        path: "/usuarios/editar/:id",
        name: "EditarUsuario",
        component: () => import('@/views/usuarios/EditarUsuario'),
        props: { titleCard: "Editar Usuario", iconoTitulo: 'fas fa-user' },
    },
    {
        path: "/usuarios/ver/:id",
        name: "VerUsuario",
        component: () => import('@/views/usuarios/VerUsuario'),
        props: { titleCard: "Ver Usuario", iconoTitulo: 'fas fa-user' },
    },
]
const admins = [
    {
        path: "/administradores/listar",
        name: "ListarAdministradores",
        component: () => import('@/views/administradores/ListarAdministradores'),
        props: { titleCard: "Administradores", iconoTitulo: 'fas fa-users', toCreateAction: '/administradores/nueva', toCreateText: 'Nuevo Admin' },
    },
    {
        path: "/administradores/nueva",
        name: "NuevoAdministrador",
        component: () => import('@/views/administradores/NuevoAdministrador'),
        props: { titleCard: "Nuevo Administrador", iconoTitulo: 'fas fa-users' },
    },
]
const instituciones = [
    {
        path: "/instituciones/listar",
        name: "ListarInstituciones",
        component: () => import('@/views/instituciones/ListarInstituciones'),
        props: { titleCard: "Instituciones", iconoTitulo: 'fas fa-building', toCreateAction: '/instituciones/nueva', toCreateText: 'Nueva Institución' },
    },
    {
        path: "/instituciones/nueva",
        name: "NuevaInstitucion",
        component: () => import('@/views/instituciones/NuevaInstitucion'),
        props: { titleCard: "Nueva Institución", iconoTitulo: 'fas fa-building' },
    },
    {
        path: "/instituciones/editar/:id",
        name: "EditarInstitucion",
        component: () => import('@/views/instituciones/EditarInstitucion'),
        props: { titleCard: "Editar Institución", iconoTitulo: 'fas fa-building' },
    },
    {
        path: "/instituciones/ver/:id",
        name: "VerInstitucion",
        component: () => import('@/views/instituciones/VerInstitucion'),
        props: { titleCard: "Ver Institucion", iconoTitulo: 'fas fa-building' },
    },
]

const mi_cuenta = [
    {
        path: "/mi-cuenta/cambiar-contraseña",
        name: "CambiarContraseña",
        component: () => import('@/views/mi_cuenta/CambiarContraseña'),
        props: { titleCard: "Cambiar contraseña", iconoTitulo: 'fas fa-key' },
    },
    {
        path: "/mi-cuenta/mis-datos",
        name: "MisDatos",
        component: () => import('@/views/mi_cuenta/MisDatos'),
        props: { titleCard: "Mis datos", iconoTitulo: 'fas fa-user' },
    },
]
const inicio = [
    {
        path: "/",
        component: () => import('@/views/Main'),
        children: [
            {
                path: "/",
                name: "Inicio",
                component: () => import('@/views/Inicio'),
                props: { titleCard: "Inicio", iconoTitulo: 'fas fa-home' },
            },
            {
                path: "/mis-recibos",
                name: "MisRecibos",
                component: () => import('@/views/MisRecibos'),
                props: { titleCard: "Mis recibos", iconoTitulo: 'fas fa-file-invoice-dollar' },
            },
            ...instituciones,
            ...admins,
            ...users,
            ...liquidacion,
            ...mi_cuenta,
            {
                path: "*",
                name: "NotFound",
                component: () => import('@/views/NotFound'),
                props: { titleCard: "Ésta ruta no existe", iconoTitulo: 'fas fa-exclamation-triangle' },
            },
        ]
    },
];
const routes = [
    {
        path: "/login",
        name: "Login",
        component: () => import('@/views/Login'),
    },
    {
        path: "/register",
        name: "Register",
        component: () => import('@/views/Register'),
    },
    {
        path: "/recuperar-password",
        name: "MailRecuperarPassword",
        component: () => import('@/views/MailRecuperarPassword'),
    },
    {
        path: "/recuperar/password/:user_id",
        name: "RecuperarPassword",
        component: () => import('@/views/RecuperarPassword'),
    },
    {
        path: "/recibo/validacion/:recibo_id",
        name: "ValidarRecibo",
        component: () => import('@/views/ValidarRecibo'),
        props: { titleCard: "Validar recibo", iconoTitulo: 'fas fa-file-invoice-dollar' },
    },
    ...inicio,
];
const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes,
    // linkExactActiveClass: "mm-active",
})

export default router